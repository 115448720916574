// WaGen___HASHKEY__6cccad0b_2023-12-15 16:25:01 !!!!修改时不要修改此行 !!!!


import fetch from '@/utils/fetch';

/*
设计注释:
未提供注释
*/
/* ---
根据年份和用户编号查询
*/
export function zwYhzdxxSelfService_getJfjlByNfAndYhbh(nf,jgbm,yhbh, pagination, meta) {
	return fetch({
		url : 'psdmszhcx/service/ZwYhzdxxSelfService/getJfjlByNfAndYhbh',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				nf : nf, //Integer
				jgbm : jgbm, //String
				yhbh : yhbh //String
			},
			pagination : pagination
		}
	})
}

/* ---
根据机构编码和用户编号查询
*/
export function zwYhzdxxSelfService_getJfjl(jgbm,yhbh, pagination, meta) {
	return fetch({
		url : 'psdmszhcx/service/ZwYhzdxxSelfService/getJfjl',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				jgbm : jgbm, //String
				yhbh : yhbh //String
			},
			pagination : pagination
		}
	})
}

